// surface
.mdc-menu-surface {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.14);
}

// text and button
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.mdc-button--outlined:not(:disabled) {
  border-color: 1px solid var(--mdc-theme-grey-700);
}

// list
.mdc-list-item {
  color: var(--mdc-theme-grey-700);
}
// snackbar
.mdc-snackbar {
  top: 8px;
  bottom: initial;
  width: auto;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 0;
  padding-top: 0;
  &--open .mdc-snackbar__surface {
    width: 100%;
  }

  i {
    // background: var(--mdc-theme-warning);
    border-radius: 50%;
    height: 24px;
    width: 24px;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
}

// list
.mdc-list {
  padding: 0px;
  li {
    font-size: 12px;
    line-height: 14px;
  }

  li > i {
    margin-right: 6px;
  }
}

// search tabs

body {
  .mdc-tab {
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
  .mdc-tab-scroller {
    .mdc-tab-indicator,
    .mdc-tab,
    .mdc-tab:not(.mdc-tab--active) .mdc-tab__text-label {
      color: #1b1b1b;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-color: #68ab0b;
    }

    .mdc-tab--active {
      color: #68ab0b;
      .mdc-tab__text-label {
        color: #68ab0b;
      }
      i {
        color: #68ab0b;
      }

      svg path {
        fill: #68ab0b;
      }
    }
  }
}

.colorBlock {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  display: block;
}
body {
  .MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: center;
  }
  .MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 600px) {
    .MuiToolbar-gutters {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .MuiToolbar-regular {
    min-height: 56px;
  }
  @media (min-width: 0px) and (orientation: landscape) {
    .MuiToolbar-regular {
      min-height: 48px;
    }
  }
  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 64px;
    }
  }
  .MuiToolbar-dense {
    min-height: 48px;
  }

  .MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
  }
  .MuiPaper-rounded {
    border-radius: 4px;
  }
  .MuiPaper-outlined {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation0 {
    box-shadow: none;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
      0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14),
      0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
      0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14),
      0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
      0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14),
      0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14),
      0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14),
      0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14),
      0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14),
      0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14),
      0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
      0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14),
      0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14),
      0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14),
      0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  }
  .MuiPaper-elevation24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }

  .MuiTable-root {
    margin: 0px 8px;
    width: calc(100% - 32px);
  }

  table.MuiTable-root {
    margin-bottom: 16px;
  }
  .MuiTablePagination-root:last-child {
    overflow: auto;
  }
  .MuiTableCell-root {
    padding: 4px 8px;
    font-size: 14px !important;
    height: auto !important;
  }

  .MuiTableRow-root {
    height: auto !important;
  }

  .MuiTable-root .MuiToolbar-gutters {
    padding: 0;
  }

  .MuiToolbar-gutters {
    padding: 0px 16px;
  }

  .MTableToolbar-title-9 {
  }

  .TableView > div > div:first-child {
    padding: 0px 20px;
  }
}

body .MuiGrid-container {
  display: flex !important;
  flex-direction: row !important;
  .MuiGrid-item {
    // display: flex;
    flex-basis: auto;
    flex-basis: calc(33.33% - 32px);
    margin: 16px;

    .MuiCard-root {
      width: calc(100vw - 400px);
    }
  }
}
