.customAutocomplete {
  position: relative;
  width: 100%;

  label {
    font-size: 12px;
    position: absolute;
    top: -12px;
    left: 12px;
    z-index: 1;
    background: white;
    padding: 0px 4px;
  }

  > div > div {
    min-height: 56px;
    border-color: var(--mdc-theme-grey-700) !important;
    box-shadow: 0 0 0 1px var(--mdc-theme-grey-200) !important;
    outline: none !important;

    > div:first-child {
      padding-left: 12px;
    }
  }
}
