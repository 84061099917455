.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  height: 100px;
  width: 100%;
}

.file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  /* horizontally and vertically center all content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  background-color: var(--mdc-theme-primary);
  box-shadow: 0 0 13px 0px var(--mdc-theme-primary);
  opacity: 1;
  /* typography */
  color: white;
  border-radius: 20;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  background-color: var(--mdc-theme-primary);
  box-shadow: 0 0 13px 0px var(--mdc-theme-primary);
  opacity: 1;
  /* typography */
  color: white;
  border-radius: 20;
}
