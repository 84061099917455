html,
body,
#root {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-display: swap;
  color: var(--mdc-theme-grey-700);
}

.link-button {
  text-decoration: none;
}

a {
  color: var(--mdc-theme-secondary);
  text-decoration: none;
  outline: none;
  transition: color 0.25s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    color: var(--mdc-theme-primary);
    i {
      color: var(--mdc-theme-secondary);
    }
  }
}
