.navbar {
  z-index: 7;
  position: relative;

  .dynamicHeading {
    text-transform: capitalize;
  }
  &:before {
    content: '';
    position: absolute;
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.15);
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
  }

  .title {
    display: flex;
    align-items: center;
    overflow: visible;
    position: relative;
    margin-left: 0;
    padding-left: 0;
    height: 100%;
  }

  .logoWrapper {
    width: 40px;
    flex-grow: 0;
    padding: 8px 12px 8px 24px;
    flex-shrink: 0;
  }

  .logoLink {
    height: 100%;
    transition: opacity 0.25s ease-in-out;

    transition: all 0.25s ease-in-out;
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }

  .logo {
    width: 48px;
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    background: url('../../assets/Lion-White.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .arrow {
    position: absolute;

    height: 10px;
    width: 10px;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
    border: 1px solid #eeeeee;
    transform: rotate(-45deg);
    opacity: 0;
    transition: opacity 0.25s linear;
  }

  .searchWrapper {
    flex-grow: 0;
    padding-left: 0px;
    width: 640px;
    margin-right: 20px;

    @media screen and (max-width: 841px) {
      width: 280px;
      margin-right: 0px;
    }
    // @media screen and (max-width: 841px) {
    //   display: none;
    //   flex-grow: 0;
    //   padding-left: 0px;
    //   position: absolute;
    //   top: 0px;
    //   background: rgba(0, 0, 0, 0.5);
    //   width: 100%;
    //   height: 100vh;
    //   margin: 0;
    //   padding: 0;
    // }
  }

  .profileWrapper {
    // width: 60px;
    // flex-grow: 0;
    position: relative;

    > div {
      overflow: visible;

      > div {
        margin-top: 7px;
        overflow: visible;
        transition: none;

        .arrow {
          transition: none;
        }
      }
      ul {
        position: relative;
      }
    }
  }

  .profileLinkWrapper {
    position: relative;
    margin-top: 0px !important;

    .arrow {
      right: 15px;
      bottom: -15px;
    }
  }

  .profileLink {
    border-radius: 100px;
    height: 40px;
    width: 40px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #436668;
    background: #dff0f3;
    text-decoration: none;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
  .profile {
    padding: 24px 16px 16px 16px;
    width: 280px;
    position: relative;

    .profileUser {
      position: relative;
      display: flex;
      padding-left: 50px;
      padding-bottom: 16px;
      flex-direction: column;
      border-bottom: 1px solid #efefef;
    }

    .profileName {
      text-transform: capitalize;
      color: var(--mdc-theme-grey-700);
      font-weight: 500;
    }

    .profileEmail {
      color: var(--mdc-theme-grey-500);
    }

    .initials {
      width: 40px;
      height: 40px;
      position: absolute;
      top: -2px;
      left: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: 'Roboto';
      font-weight: 500;
      color: #436668;
      background: #dff0f3;
      text-decoration: none;
    }

    .signOut {
      margin-top: 16px;
      display: block;
      text-align: right;
      font-weight: 500;
      color: var(--mdc-theme-grey-700);
      span {
        font-weight: 500;
        color: var(--mdc-theme-grey-700);
      }
    }
  }

  .helpLinkWrapper {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    margin-right: 16px;

    a {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: var(--mdc-theme-grey-300);
      &:hover,
      &:focus {
        opacity: 0.8;
      }
      span {
        font-size: 18px;
        margin-top: 3px;
        font-weight: bold;
        color: var(--mdc-theme-dark);
      }
    }
    .arrow {
      right: 15px;
      bottom: -15px;
    }
  }

  .helpList {
    width: 300px;
    i {
      border-radius: 100px;
      background: #dff0f3;
      margin-right: 16px;
      background: var(--mdc-theme-grey-300);
      height: 40px;
      width: 40px;
      font-size: 18px;
    }
  }
}
