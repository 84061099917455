.core-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--mdc-theme-grey-light);

  @media screen and (max-width: 841px) {
    min-height: 100vh;
  }

  .loading {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  button {
    margin-top: 0px;
  }

  .core-layout-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}
