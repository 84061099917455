:root {
  --font-family: Roboto, sans-serif;

  //Should not be in here.
  --box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  --box-shadow-dark: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  --box-shadow-alternate: 6px 6px 6px 0px rgba(153, 153, 153, 0.2);
  --font-smallest: 8pt;
  --font-tiny: 9pt;
  --font-small: 10pt;
  --font-medium: 11pt;
  --font-large: 14pt;
  --font-larger: 16pt;
  --font-largest: 24pt;
  --font-extreme: 36pt;
  --border-radius: 5px;
  --border-radius-small: 3px;

  //New theme variables
  --theme-green: rgb(101, 173, 90);
  --theme-blue: rgb(41, 134, 245);
  --theme-blue-hover: rgb(41, 134, 245);

  --grey: rgb(80, 80, 80);
  --grey-opaque: rgba(80, 80, 80, 0.7);
  --grey-border: #ddd;
  --grey-alternative: #ccced2;
  --grey-border-2: rgb(215, 215, 215);
  --grey-hover: rgb(241, 241, 241);

  --grey-bg: rgb(182, 182, 182);

  --primary: rgb(65, 65, 65);
  --primary-light: rgb(97, 97, 97);

  --green: rgb(101, 173, 90);
  --green-dark: rgb(96, 158, 10);
  --green-light: #4caf50;
  --green-lighter: rgb(213, 229, 186);
  --green-opaque: rgb(76, 175, 80, 0.3);

  --blue: rgb(0, 145, 174);
  --blue-opaque: rgba(0, 145, 175, 0.3);
  --blue-olympic: rgb(80, 147, 237); // https://colornamer.robertcooper.me/

  --red: #f44336;
  --red-opaque: rgb(244, 67, 54, 0.3);
  --red-negative: #dc2626;

  --orange: #ff9800;
  --orange-opaque: rgba(255, 152, 0, 0.3);

  --off-white: #eceff1;
  --white: #fff;
  --white-secondary: rgb(241, 241, 242);

  --black: #222;
  --black-light: #333;

  --teal: rgb(57, 122, 145);
  --teal-lighter: rgb(235, 253, 255);
  --teal-light: rgb(196, 223, 226);
  --teal-medium: rgb(167, 207, 214);

  //Alert colors
  --alert-yellow-background: rgb(255, 251, 235);
  --alert-yellow-border: rgb(252, 231, 172);
  --alert-yellow-color: rgb(247, 156, 28);
  --alert-red-background: rgb(254, 242, 242);
  --alert-red-border: rgb(250, 206, 206);
  --alert-red-color: rgb(240, 64, 71);

  --text-link-color: #0091ae;

  // RMWC variables
  --mdc-theme-primary: #414141;
  --mdc-theme-secondary: #68ab0b;
  --mdc-theme-error: #f44336;
  --mdc-theme-error-opaque: #fdd9d7;
  --mdc-theme-background: #efefef;
  // --mdc-theme-surface: #fff;
  // --mdc-theme-on-primary: rgba(0, 0, 0, 0.87);
  // --mdc-theme-on-secondary: rgba(0, 0, 0, 0.87);
  --mdc-theme-on-surface: #616161;
  // --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: #616161;
  // --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  // --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  // --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  // --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  // --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  // --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  // --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  // --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  // --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  // --mdc-theme-text-primary-on-dark: white;
  // --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  // --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  // --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  // --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);

  --mdc-theme-primary-light: #6c6c6c;
  --mdc-theme-primary-dark: #1b1b1b;
  --mdc-theme-primary-dark-opaque: rgb(27, 27, 27, 0.2);
  --mdc-theme-secondary-light: #68ab0b;
  --mdc-theme-secondary-lighter: rgba(104, 171, 11, 0.2);
  --mdc-theme-disabled: #f5f5f5;
  --mdc-theme-success: #68ab0b;
  --mdc-theme-warning: #ff9800;
  --mdc-theme-warning-light: rgb(255, 245, 229);
  --mdc-theme-info: #2196f3;
  --mdc-theme-link: #0091ae;

  --mdc-theme-light-blue-opaque: rgba(196, 223, 226, 0.2);
  --mdc-theme-light-blue: #c4dfe2;
  --mdc-theme-dark-blue: #3c98ab;
  --mdc-theme-blue-alternate: #8ac0cb;
  --mdc-theme-blue-alternate-2: #eef7f8;

  --mdc-theme-grey-light: #eceff1;
  --mdc-theme-grey-50: #fafafa;
  --mdc-theme-grey-200: #eeeeee;
  --mdc-theme-grey-300: #e0e0e0;
  --mdc-theme-grey-400: #bdbdbd;
  --mdc-theme-grey-500: #9e9e9e;
  --mdc-theme-grey-500-light: rgba(158, 158, 158, 0.2);
  --mdc-theme-grey-600: #757575;
  --mdc-theme-grey-600-light: rgba(117, 117, 117, 0.2);
  --mdc-theme-grey-700: #616161;
  --mdc-theme-grey-800: #424242;

  --mdc-theme-light: #eceff1;
  --mdc-theme-dark: #616161;

  --mdc-theme-default: #cfd8dc;
  --mdc-theme-white: #fff;
  --mdc-theme-black: #000000;

  // CHARTS
  --chart-color-1: 60, 152, 171;
  --chart-color-2: 138, 192, 203;
  --chart-color-3: 96, 223, 226;

  // Spacing
  --spacing-card: 24px; // From Figma --SpacingCards
  --spacing-card-md: 18px;
  --spacing-card-sm: 8px;
  --spacing-card-xs: 4px;

  --padding-card-lg: 36px;
  --padding-card: 24px; // From Figma --PaddingCards
  --padding-card-md: 12px;
  --radius-card: 12px; // From Figma --RadiusCard
  --radius-card-sm: 6px;

  --spacing-content-tight: 12px;
  --spacing-content-loose: 16px;
}
