.authenticated-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  background: var(--mdc-theme-grey-50);

  &.inIframe {
    .drawerToggle {
      display: none;
    }
    .drawer-content {
      transform: translateX(0px) !important;
      width: 100% !important;
      padding: 0px;
    }
  }
  .content {
    display: flex;
    flex-grow: 1;
    position: relative;
    z-index: 1;
  }

  .drawerToggle {
    position: absolute;
    top: 35px;
    left: 256px;
    margin-left: -10px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid var(--mdc-theme-grey-400);
    border-radius: 100%;
    color: var(--mdc-theme-grey-700);
    z-index: 10;
    transition: all 0.25s ease-in-out;
    opacity: 1;
    text-decoration: none;

    i {
      font-size: 18px;
    }

    &:hover,
    &:focus {
      background: var(--mdc-theme-secondary);
      border-color: var(--mdc-theme-secondary);
      i {
        color: white;
      }
    }

    &.closed {
      transform: translateX(-236px);
    }
  }

  .drawer-content {
    padding: 16px;
    padding-bottom: 8px;
    width: calc(100% - 288px);
    position: relative;
    z-index: 9;
    transform: translateX(256px);
    transition: transform 0.25s ease-in-out, width 0.25s ease-in-out;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15), inset 1px 0px 0px #eeeeee;

    &.closed {
      transform: translateX(20px);
      width: calc(100% - 30px);
    }

    > div {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    // &.mobile {
    //   width: 100%;
    //   margin-left: 0 !important;
    //   max-width: 100%;
    // }
  }
}
