.customModal {
  &.fullscreen {
    > div > div > div {
      max-width: 98vw !important;
      width: 98vw !important;
    }
  }
  > div > div > div {
    max-width: 768px !important;
    width: 768px !important;
  }

  form {
    > div:last-child {
      padding: 16px 24px;
    }

    h2 + div {
      padding-top: 24px !important;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 24px;

    &:first-child {
      margin-top: 0px;
    }

    > label,
    > div {
      width: 100%;
    }
  }

  .error {
    margin-bottom: 16px;
    span {
      color: var(--mdc-theme-error);
    }
  }

  .resultTable {
    width: 100%;
    margin-top: 30px;
    table {
      border-collapse: collapse;
      text-align: left;
      width: calc(100% - 2px);
    }

    td,
    th {
      border: 1px solid grey;
      margin: 0;
      padding: 5px;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          color: var(--mdc-theme-secondary);
        }
      }
    }
  }

  .csvFormError {
    text-align: center;
    color: var(--mdc-theme-error);
  }
  .csvReader {
    height: 300px;
    width: 100%;
    position: relative;
    > div {
      height: 260px;
      width: calc(100% - 40px);
      cursor: pointer;
      position: relative;
      > div {
        border-width: 2px;
        border-radius: 20px;
        padding: 20px;
        border-color: rgb(204, 204, 204);
        border-style: dashed;
      }
    }
  }

  input[type='file'] {
    opacity: 0;
  }

  .customLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
  }

  .dualChartContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    > div {
      width: 50%;
    }
  }

  .options {
    position: absolute;
    right: 0px;
    top: 24px;
    .controls {
      display: flex;
      align-items: center;
      > label {
        margin: 0px 16px;
        max-height: 32px;
      }
      button {
        margin-right: 16px;
      }
    }
  }
}
