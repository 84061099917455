.drawer {
  height: calc(100% - 56px);
  position: fixed;
  width: 256px;
  transition: all 0.25s ease-in-out;
  background: var(--mdc-theme-grey-light);
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15), inset 1px 0px 0px #eeeeee;

  &.inIframe {
    display: none !important;
  }
  &.disableDrawer {
    opacity: 0.5;
    .drawerBlock,
    .drawerBlock * {
      cursor: not-allowed;
    }
    .backgroundItem {
      display: none !important;
    }
  }
  + div {
    transition: all 0.25s ease-in-out;
    // transform: translateX(0);
  }

  &.closed {
    // transform: translateX(-236px);
    opacity: 0;
  }
  &.open {
    // transform: translateX(0);
    z-index: 8;
  }
  .drawerContent {
    height: 100%;
    overflow: auto;

    > ul {
      > li {
        &.active {
          color: var(--mdc-theme-secondary) !important;
          i {
            color: var(--mdc-theme-secondary) !important;
          }
          &:before {
            background: var(--mdc-theme-secondary-lighter) !important;
          }
        }
      }
    }
  }

  .drawerTabs {
    background: var(--mdc-theme-grey-50);
    > div {
      width: 230px;
      background: var(--mdc-theme-grey-50);
    }
  }

  .drawerTab {
    width: 25%;
    max-width: 25%;
    padding: 0;
    position: relative;
    overflow: hidden;
    // border: 1px solid var(--mdc-theme-secondary);

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
      &:after {
        border-color: transparent transparent transparent var(--mdc-theme-secondary);
      }
    }
  }

  .back {
    display: flex;
    align-items: center;
    margin: 12px 16px 8px 16px;
    text-decoration: none;
    // color: var(--mdc-theme-link);
    color: var(--mdc-theme-grey-700);
    transition: all 0.25s ease-in-out;
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
      .caption {
        color: var(--mdc-theme-secondary);
      }
      i {
        color: var(--mdc-theme-secondary);
      }
    }
    i {
      margin-right: 3px;
      border: none;
      color: inherit;
    }
    .caption {
      display: flex;
      border-bottom: 1px solid transparent;
      transition: all 0.25s ease-in-out;
    }
    i {
      transition: all 0.25s ease-in-out;
    }
    .ellipsis {
      max-width: 195px;
      overflow: hidden;
      white-space: pre;
      text-overflow: ellipsis;
    }
  }

  .drawerBlock {
    height: calc(100% - 60px);
    overflow: auto;
  }

  .backgroundItem {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--mdc-theme-grey-500-light);
    display: none;
    border-radius: 4px;
    transition-duration: 0.2s;
    transition-property: color, background-color;
    pointer-events: none;

    &.active {
      display: block;
      background: var(--mdc-theme-secondary-lighter) !important;
    }
  }
}
